import { CHANGE_ADDONS_VALUE } from "../actions/actionType";


const initialState = {
    form: {
        type: "",
        data: {
            confidentiality: {
                editable: false,
                content: "IMPORTANT : Le contenu de cet e-mail et toutes les pièces jointes sont confidentiels. Il est strictement interdit de partager une quelconque partie de ce message avec un tiers, sans le consentement écrit de l‘expéditeur. Si vous avez reçu ce message par erreur, veuillez répondre à ce message et procéder à sa suppression, afin que nous puissions garantir qu‘une telle erreur ne se reproduira plus."
            },
            environmental: {
                editable: false,
                content: "Veuillez ne pas imprimer cet e-mail sauf si cela est nécessaire. Chaque e-mail non-imprimé aide l‘environnement."
            },
            custom: {
                editable: true,
                content: ""
            }
        },
        fontSize: 12,
        width: 450,
        selected: "confidentiality",
        amazonBadge: "",
        appleBadge: "",
        ebayBadge: "",
        googleBusinessBadge: "",
        googlePlayBadge: "",
        badgeShape: 1,
        badgeHeight: 50,
        badgePaddingTop: 15,
        badgeColor: 1
    }
}

const addonsReducer = (prevState = initialState, action) => {
    switch(action.type){
        case CHANGE_ADDONS_VALUE:
            let stringifiedPrevState = JSON.stringify(prevState);
            let newState = JSON.parse(stringifiedPrevState);

            if(action.key === "custom"){
                newState["form"]["data"]["custom"]["content"] = action.value
                newState["form"]["selected"] = "custom"
            }
            else if(action.key === "reset"){
                newState = initialState
            }
            else if(action.key === "selected"){
                if(action.value !== "custom" && newState["form"]["selected"] === "custom")
                    newState["form"]["data"]["custom"]["content"] = ""

                newState["form"]["selected"] = action.value
            }
            else newState["form"][action.key] = action.value;
            return {
                ...newState
            }
        
        default: return prevState
    }
}

export default addonsReducer