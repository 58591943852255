import React, { useState, useEffect } from "react"; // 17 mars 2024
import "./main.css";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import UploadIcon from "@mui/icons-material/Upload";
import { useSelector, useDispatch } from "react-redux";
import {
	changeMainInput,
	addField,
	removeField,
} from "../redux/actions/mainActions";
import axios from "axios";
// import { loadFile } from "./Template1";
import { validateFile } from './fileValidator'; // 16 mars 2024

const Input = styled("input")({
	display: "none",
});

function Main() {
	const [open, setOpen] = useState(false);
	const [field, setField] = useState({
		fieldValue: "",
		fieldType: "Text",
		fieldName: "",
	});

	const form = useSelector((state) => state.main.form);

	const dispatch = useDispatch();

	// Extrait le token de l'URL
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get('token');

	// Obtient l'adresse IP du client
	const [userIp, setUserIp] = useState(null);

	useEffect(() => {
		fetch('https://api.ipify.org?format=json')
			.then(response => response.json())
			.then(data => {
				setUserIp(data.ip);
			});
	}, []);

	const handleAvatarClick = (image) => {
		if (!userIp) {
			console.error('User IP not yet loaded');
			return;
		}

		axios
			.post(
				"https://sign.lsc.re/tools-backend/image-upload",
				image,
				{
					headers: {
						'Authorization': token,
						'X-Forwarded-For': userIp
					}
				}
			)
			.then((res) => {
				if (res.status === 200) {
					dispatch(
						changeMainInput(
							"image",
							"https://sign.lsc.re/tools-backend/uploads/EmailGenerator/" +
							res.data
						)
					);
					setOpen(true);
					setTimeout(function () {
						setOpen(false);
					}, 3000);
				} else if (res.status === 401) {
					// Handle unauthorized error
					console.error('Unauthorized');
				} else {
					// Handle other server errors
					console.error(`Server error: ${res.status}`);
				}
			})
			.catch((error) => {
				// Handle network errors
				console.error(`Network error: ${error}`);
			});
	};

	// const handleAvatarClick = (image) => {
	// 	// Obtient l'adresse IP du client
	// 	fetch('https://api.ipify.org?format=json')
	// 		.then(response => response.json())
	// 		.then(data => {
	// 			const userIp = data.ip;

	// 			// Envoie une requête au service de token pour vérifier le token
	// 			fetch(`https://sign.lsc.re/tools-backend/verify?token=${token}&ip=${userIp}`)
	// 				.then(response => response.json())
	// 				.then(data => {
	// 					if (data.authorized) {
	// 						// Si l'utilisateur est autorisé, envoie la requête d'upload
	// 						axios
	// 							.post(
	// 								"https://sign.lsc.re/tools-backend/image-upload",
	// 								image
	// 							)
	// 							.then((res) => {
	// 								dispatch(
	// 									changeMainInput(
	// 										"image",
	// 										"https://sign.lsc.re/tools-backend/uploads/EmailGenerator/" +
	// 										res.data
	// 									)
	// 								);
	// 								setOpen(true);
	// 								setTimeout(function () {
	// 									setOpen(false);
	// 								}, 3000);
	// 							});
	// 					} else {
	// 						// Si l'utilisateur n'est pas autorisé, redirige vers le formulaire du blog
	// 						window.location.href = 'https://ludovicclain.com/outil-en-ligne-gratuit-signature-de-mail-votre-carte-de-visite-numerique-personnalisee/';
	// 					}
	// 				});
	// 		});
	// };

	return (
		<div className="main">
			<Grid container rowSpacing={1}>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>
							Prénom
							<span className="star">*</span>
						</label>
						<input
							value={form.firstName}
							onChange={(e) =>
								dispatch(
									changeMainInput("firstName", e.target.value)
								)
							}
							required
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Nom</label>
						<input
							value={form.lastName}
							onChange={(e) =>
								dispatch(
									changeMainInput("lastName", e.target.value)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Mobile</label>
						<input
							value={form.mobileNumber}
							onChange={(e) =>
								dispatch(
									changeMainInput(
										"mobileNumber",
										e.target.value
									)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>
							E-mail
							<span className="star">*</span>
						</label>
						<input
							type="email"
							value={form.email}
							onChange={(e) =>
								dispatch(
									changeMainInput("email", e.target.value)
								)
							}
							required
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Profession</label>
						<input
							value={form.jobTitle}
							onChange={(e) =>
								dispatch(
									changeMainInput("jobTitle", e.target.value)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Département</label>
						<input
							value={form.department}
							onChange={(e) =>
								dispatch(
									changeMainInput(
										"department",
										e.target.value
									)
								)
							}
						/>
					</div>
				</Grid>

				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Entreprise</label>
						<input
							value={form.companyName}
							onChange={(e) =>
								dispatch(
									changeMainInput(
										"companyName",
										e.target.value
									)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Site internet</label>
						<input
							value={form.website}
							onChange={(e) =>
								dispatch(
									changeMainInput("website", e.target.value)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Téléphone professionnel</label>
						<input
							value={form.officePhone}
							onChange={(e) =>
								dispatch(
									changeMainInput(
										"officePhone",
										e.target.value
									)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<div className="main-input">
						<label>Fax professionnel</label>
						<input
							value={form.officeFax}
							onChange={(e) =>
								dispatch(
									changeMainInput("officeFax", e.target.value)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<div className="main-input">
						<label>Adresse postale</label>
						<input
							value={form.address}
							onChange={(e) =>
								dispatch(
									changeMainInput("address", e.target.value)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<div className="main-input">
						<label>Adresse Ligne 2</label>
						<input
							value={form.addressLine2}
							onChange={(e) =>
								dispatch(
									changeMainInput(
										"addressLine2",
										e.target.value
									)
								)
							}
						/>
					</div>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<div className="main-input">
						<label>LOGO/PHOTO/IMAGE DE PROFIL</label>
					</div>
				</Grid>
				<Grid
					style={{ margin: "0vw 0.9vw" }}
					item
					xs={12}
					sm={12}
					md={12}
				>
					<label htmlFor="contained-button-file">
						{/* <Input
							accept="image/*"
							id="contained-button-file"
							multiple
							type="file"
							onChange={(e) => {
								const formData = new FormData();
								formData.append(
									"my-image-file",
									e.target.files[0],
									e.target.files[0].name
								);
								handleAvatarClick(formData);
							}}
						/> */}
						<Input
							accept="image/jpeg,image/png,image/gif"
							id="contained-button-file"
							multiple
							type="file"
							onChange={(e) => {
								const file = e.target.files[0];
								validateFile(file).then(() => {
									const formData = new FormData();
									formData.append("my-image-file", file, file.name);
									handleAvatarClick(formData);
								}).catch(error => {
									alert(error);
									e.target.value = '';
								});
							}}
						/>
						<Button variant="contained" component="span">
							<UploadIcon />
							Téléverser une image
						</Button>
					</label>
				</Grid>
			</Grid>

			<p>Dimensions min. : 100px X 100px.</p>
			<p>Dimensions max. : 800px X 800px.</p>
			<p>
				Formats de fichier pris en charge : JPG, JPEG, PNG, BMP, GIF.
			</p>
			<br />
			<p style={{ fontSize: "9px" }}>
				Suggestion : utilisez une image carrée pour un meilleur visuel.
			</p>
			<p style={{ fontSize: "9px" }}>
				L‘image doit être de taille adaptée à votre signature car elle sera affichée en taille réelle.
			</p>

			{/* Alert Button Part */}
			<div className="alert">
				<Box sx={{ width: "100%" }}>
					<Collapse in={open}>
						<Alert
							action={
								<IconButton
									aria-label="close"
									color="inherit"
									size="small"
									onClick={() => {
										setOpen(false);
									}}
								>
									<CloseIcon fontSize="inherit" />
								</IconButton>
							}
							sx={{ mb: 2 }}
						>
							Avatar téléversé avec succès !
						</Alert>
					</Collapse>
				</Box>
			</div>

			{/* additional fields */}
			<h2
				style={{
					paddingLeft: "0.5vw",
					fontFamily: "Poppins",
					marginBottom: "0",
				}}
			>
				Champs additionnels
			</h2>

			{form.additionalField.map((eachField) => (
				<div className="add-field">
					<input
						key={eachField.fieldName}
						value={eachField.fieldName}
					/>
					<select
						key={eachField.fieldName}
						value={eachField.fieldType}
						disabled
					>
						<option value="Text">Texte</option>
						<option value="Link">Lien</option>
						<option value="Phone">Téléphone</option>
					</select>

					<input
						// style="width: 100px !important"
						key={eachField.fieldName}
						value={eachField.fieldValue}
						disabled
					/>

					<Button
						className="add-field-button"
						onClick={() => {
							dispatch(removeField(eachField.fieldName));
						}}
					>
						<DeleteIcon style={{ color: "white" }} />
					</Button>
				</div>
			))}

			<div className="add-field">
				<input
					placeholder="Nom du champ"
					value={field.fieldName}
					onChange={(e) =>
						setField({
							fieldName: e.target.value,
							fieldType: field.fieldType,
							fieldValue: field.fieldValue,
						})
					}
				/>
				<select
					// key={social.socialID}
					value={field.fieldType}
					onChange={(e) =>
						setField({
							fieldName: field.fieldName,
							fieldType: e.target.value,
							fieldValue: field.fieldValue,
						})
					}
				>
					<option value="Text">Texte</option>
					<option value="Link">Lien</option>
					<option value="Phone">Téléphone</option>
				</select>

				<input
					placeholder="Valeur du champ"
					value={field.fieldValue}
					onChange={(e) =>
						setField({
							fieldName: field.fieldName,
							fieldType: field.fieldType,
							fieldValue: e.target.value,
						})
					}
				/>

				<Button
					disabled={
						field.fieldName.length == 0 ||
						field.fieldValue.length == 0
					}
					className="add-field-button"
					onClick={() => {
						dispatch(
							addField(
								field.fieldName,
								field.fieldType,
								field.fieldValue
							)
						);

						setField({
							fieldName: "",
							fieldType: "Text",
							fieldValue: "",
						});
					}}
				>
					<AddIcon style={{ color: "white" }} />
					<strong>Ajouter</strong>
				</Button>
			</div>
		</div>
	);
}

export default Main;