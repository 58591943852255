// fileValidator.js

export function validateFile(file) {
  return new Promise((resolve, reject) => {
    // Check file size (150KB)
    if (file.size > 150 * 1024) {
      reject('Le fichier est trop lourd. La poids maximal est de 150 Ko.');
      return;
    }

    // Check file type
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      reject('Type de fichier invalide. Seuls les formats JPG, JPEG, PNG et GIF sont autorisés.');
      return;
    }

    // Check image dimensions
    const img = new Image();
    img.onload = function () {
      if (this.width < 100 || this.height < 100) {
        reject('Les dimensions de l‘image sont trop petites. La taille minimale est de 100 px sur 100 px.');
        return;
      }
      if (this.width > 800 || this.height > 800) {
        reject('Les dimensions de l‘image sont trop grandes. La taille maximale est de 800 px sur 800 px.');
        return;
      }
      resolve();
    };
    img.onerror = function () {
      reject('Fichier image invalide.');
    };
    img.src = URL.createObjectURL(file);
  });
}