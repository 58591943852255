import React from "react";
import Fab from "@mui/material/Fab";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const fabStyle = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
  zIndex: 10
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FAQ = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Fab
        onClick={handleClickOpen}
        sx={fabStyle}
        aria-label={"FAQ"}
        color={"primary"}
      >
        <h3>FAQ</h3>
      </Fab>
      <Dialog
        fullScreen
        style={{
          margin: "4vh 4vw",
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1, textAlign: "center", fontFamily: "Recursive" }} variant="h6" component="div">
              FAQ pour le Générateur de Signatures d'E-mails
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ margin: "2rem" }}>
          <h4 style={{ color: "black" }}>Comment puis-je commencer à créer ma signature d'e-mail ?</h4>
          <span>Pour commencer, naviguez dans le menu "Principal" où vous pourrez entrer vos coordonnées de base et téléverser une image de profil. Vous avez également la possibilité d'ajouter des champs supplémentaires si nécessaire.</span>

          <h4 style={{ color: "black" }}>Comment ajouter mes réseaux sociaux à ma signature ?</h4>
          <span>Rendez-vous dans le menu "Réseaux" pour lier vos comptes de réseaux sociaux. Vous pourrez sélectionner les plateformes que vous souhaitez afficher dans votre signature et y renseigner les URL de vos profils.</span>

          <h4 style={{ color: "black" }}>Est-il possible de personnaliser le design de ma signature ?</h4>
          <span>Absolument. Le menu "Design" vous offre une liberté totale sur le choix des polices, des couleurs et des formes pour que votre signature reflète au mieux votre identité ou celle de votre marque.</span>

          <h4 style={{ color: "black" }}>Que signifie "CTA" et comment puis-je l'utiliser ?</h4>
          <span>CTA signifie "Call To Action". Ce menu vous permet d'incorporer un appel à l'action dans votre signature, tel qu'un lien vers votre agenda pour prendre rendez-vous. Vous pouvez choisir entre un bouton texte ou une image cliquable.</span>

          <h4 style={{ color: "black" }}>Qu'est-ce que la fonction "Addon" ?</h4>
          <span>La fonction "Addon" vous permet d'ajouter des éléments supplémentaires à votre signature, comme des badges cliquables ou des clauses de fin d'e-mail (environnementale, de confidentialité ou personnalisée).</span>

          <h4 style={{ color: "black" }}>Comment choisir la disposition de ma signature ?</h4>
          <span>Une fois votre signature prête, vous pouvez utiliser les modèles situés dans la partie inférieure droite de l'interface pour choisir le thème et la disposition des éléments qui vous conviennent le mieux.</span>

          <h4 style={{ color: "black" }}>Que faire si je veux recommencer ma signature depuis le début ?</h4>
          <span>Si vous souhaitez recommencer, cliquez sur le bouton "Réinitialiser" pour effacer toutes les données saisies et repartir d'une page vierge.</span>

          <h4 style={{ color: "black" }}>Comment obtenir le code source de ma signature ?</h4>
          <span>Une fois satisfait(e) de votre signature, utilisez le bouton "Code source" pour copier le code HTML de votre signature dans votre presse-papier. Vous pourrez ensuite l'intégrer dans votre client de messagerie.</span>

          <h4 style={{ color: "black" }}>Comment intégrer ma signature dans mon client de messagerie ?</h4>
          <span>Si vous avez besoin d'aide pour implémenter votre signature dans votre gestionnaire de messagerie, <a title="Voir le tutoriel sur YouTube" href="https://www.youtube.com/watch?v=3VEAV8s3QBg" target="_blank">un tutoriel est disponible</a> pour les utilisateurs de Mail (MacOS) et Thunderbird. Pour des demandes plus techniques, n'hésitez pas à me contacter via mon blog pour un devis personnalisé.</span>
        </div>
      </Dialog>
    </div>
  );
};

export default FAQ;
