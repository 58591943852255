import React from "react";
import './about.css'

function About() {
  return (
    <div className="about">
      <h1 style={{fontSize:"3.5rem"}}>Générateur de signatures d‘e-mails</h1>
      <hr className="new1" />
      <p>
      Améliorez votre <strong>communication professionnelle</strong> avec ce générateur de signatures d‘e-mails, conçu pour offrir une personnalisation sans égale et une intégration transparente avec votre <strong>identité de marque</strong>. Que vous visiez à <strong>améliorer votre réseau professionnel</strong> ou à optimiser la <strong>reconnaissance de votre entreprise</strong>, cet outil est la clé pour <strong>créer des signatures d‘e-mails qui parlent pour vous</strong>. Profitez d‘une <strong>interface utilisateur intuitive</strong> et d‘une gamme de <strong>fonctionnalités avancées</strong> pour concevoir une signature d‘e-mail qui renforce la confiance, augmente l‘engagement et promeut efficacement votre marque à chaque envoi. <mark>Lancez-vous dès maintenant</mark> et faites de votre signature d‘e-mail un vecteur de succès professionnel.
      </p>
    </div>
  );
}

export default About;
