import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/App";
import "./index.css";
// ReactDOM.render(<App />, document.getElementById("root"));

// Extrait le token de l'URL
const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');

// Obtient l'adresse IP du client
// fetch('https://api.ipify.org?format=json')
//   .then(response => response.json())
//   .then(data => {
//     const userIp = data.ip;

//     // Envoie une requête au service de token pour vérifier le token
//     fetch(`https://sign.lsc.re/tools-backend/verify?token=${token}&ip=${userIp}`)
//       .then(response => response.json())
//       .then(data => {
//         if (data.authorized) {
//           // Si l'utilisateur est autorisé, rend l'application
//           ReactDOM.render(<App />, document.getElementById("root"));
//         } else {
//           // Si l'utilisateur n'est pas autorisé, redirige vers le formulaire du blog
//           window.location.href = 'https://ludovicclain.com/outil-en-ligne-gratuit-signature-de-mail-votre-carte-de-visite-numerique-personnalisee/';
//         }
//       });
//   });
fetch('https://api.ipify.org?format=json')
  .then(response => {
    if (!response.ok) {
      console.error('Failed to fetch IP:', response.status, response.statusText);
    }
    return response.json();
  })
  .then(data => {
    const userIp = data.ip;
    console.log('Fetched IP:', userIp);

    // Envoie une requête au service de token pour vérifier le token
    fetch(`https://sign.lsc.re/tools-backend/verify?token=${token}&ip=${userIp}`)
      .then(response => {
        if (!response.ok) {
          console.error('Failed to verify token:', response.status, response.statusText);
        }
        return response.json();
      })
      .then(data => {
        console.log('Token verification response:', data);
        if (data.authorized) {
          // Si l'utilisateur est autorisé, rend l'application
          ReactDOM.render(<App />, document.getElementById("root"));
        } else {
          // Si l'utilisateur n'est pas autorisé, redirige vers le formulaire du blog
          window.location.href = 'https://ludovicclain.com/outil-en-ligne-gratuit-signature-de-mail-votre-carte-de-visite-numerique-personnalisee/';
        }
      });
  })
  .catch(error => {
    console.error('Error:', error);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
